<template>
  <div>
    <div class="row justify--space-between pt-3" v-if="loading">
      <span>{{$t('expectedTime')}}<span>{{expextedTime}}</span></span>
      <span>{{$t('distance')}}<span>{{rdistance}}</span></span>
      <!-- <span>{{timeNow - expextedTime >= 3 ? '' :''}}</span> -->
      <span>{{$t('timeNow')}} <span>{{timeNow}}</span></span>

    </div>
    <div class="row">
      <div id="map" class="google-map fill-height" ref="googleMapWaypoints" />
      <div id="sidebar">
        <p v-if="distance">{{$t('TotalDistance')}}: <span id="total">{{distance}}</span></p>
        <div id="panel"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as GoogleMapsLoader from 'google-maps'
import * as spinners from 'epic-spinners'
import axios from 'axios'
import { drawPoly } from '../../../helper/Pahts'

export default {
  name: 'google-map',
  data () {
    return {
      routeName: '',
      timeNow: '',
      rdistance: '',
      expextedTime: '',
      distance: '',
      DeafultTime: '',
      waypoints: [],
      routes: [],
      routsSelect: {
        value: [],
        options: [],
      },
      from: null,
      departureTime: null,
      loading: false,
      googleMap: null,
      smap: null,
      showTrafic: false,
      showPrimariy: true,
      showsec: true,
      trafficLayer: null,
      markers: [],
      IW: null,
      directionsRenderer: null,
    }
  },
  components: {
    ...spinners,
  },
  async mounted () {
    await this.PageRender()
  },
  methods: {
    async PageRender () {
      const self = this

      GoogleMapsLoader.KEY = 'AIzaSyC6Wt_40qWZ7up5hvZi9GwzxYWmwjnXggs'
      GoogleMapsLoader.VERSION = '3.35'
      await GoogleMapsLoader.load((google) => {
        self.googleMap = google
        function createCenterControl (map) {
          const controlButton = document.createElement('button')

          // Set CSS for the control.
          controlButton.style.backgroundColor = '#fff'
          controlButton.style.border = '2px solid #fff'
          controlButton.style.borderRadius = '3px'
          controlButton.style.boxShadow = 'rgb(0 0 0 / 30%) 0px 1px 4px -1px'
          controlButton.style.color = 'rgb(25,25,25)'
          controlButton.style.cursor = 'pointer'
          controlButton.style.fontFamily = 'Roboto,Arial,sans-serif'
          controlButton.style.fontSize = '16px'
          controlButton.style.lineHeight = '38px'
          controlButton.style.margin = '8px 0 22px'
          controlButton.style.padding = '0 5px'
          controlButton.style.width = '40px'
          controlButton.style.textAlign = 'center'
          controlButton.innerHTML = '<i class="fa fa-clock-o fa-lg"></i>'
          controlButton.title = 'اضغط لتحسب ازدحام الطريق'
          controlButton.type = 'button'
          // Setup the click event listeners: simply set the map to Chicago.
          controlButton.addEventListener('click', () => {
            self.render(new google.maps.LatLng(24.835745918122562, 46.72987580001744))
          })
          return controlButton
        }
        /* eslint-disable no-new */
        self.smap = new google.maps.Map(this.$refs.googleMapWaypoints, {
          center: new google.maps.LatLng(24.774265, 46.738586),
          zoom: 11,
          streetViewControl: false,
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          scaleControl: true,
          fullscreenControl: false,
          mapTypeControl: false,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        })
        drawPoly(google, self.smap)

        const centerControlDiv = document.createElement('div')
        // Create the control.
        const centerControl = createCenterControl(self.smap)

        // Append the control to the DIV.
        centerControlDiv.appendChild(centerControl)
        centerControlDiv.className = 'calcRoute'
        self.smap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv)
        self.smap.addListener('click', (mapsMouseEvent) => {
          console.log('test')
          if (self.from) {
            self.routes.forEach(stepPoly => {
             // eslint-disable-next-line no-unused-expressions
             stepPoly?.setMap(null)
            })
            self.from = null
            self.distance = ''
            self.loading = false
            self.waypoints = []
            self.routes = []
            self.directionsRenderer.setMap(null)
          }
          self.from = mapsMouseEvent.latLng
          const directionsService = new google.maps.DirectionsService()
          self.directionsRenderer = new google.maps.DirectionsRenderer({
            draggable: true,
            map: self.smap,
            panel: document.getElementById('panel'),
          })

          self.directionsRenderer.addListener('directions_changed', () => {
            const directions = self.directionsRenderer.getDirections()

            if (directions) {
              this.computeTotalDistance(directions)
            }
          })
          var destination = new google.maps.LatLng(24.835745918122562, 46.72987580001744)
          this.displayRoute(self.from, destination, directionsService, self.directionsRenderer, [])
        })
      })
    },
    displayRoute (origin, destination, service, display, waypoints) {
      service
        .route({
          origin: origin,
          destination: destination,
          travelMode: 'DRIVING',
          durationInTraffic: true,
          language: 'ar',
        })
        .then((result) => {
          display.setDirections(result)
        })
        .catch((e) => {
          alert('Could not display directions due to: ' + e)
        })
    },
    computeTotalDistance (result) {
      if (result?.request?.waypoints?.length) {
        this.waypoints = []
        result.request.waypoints.forEach(point => {
          console.log('point', point)
          this.waypoints.push({ latitude: point.location.lat(), longitude: point.location.lng() })
        })
      }

      let total = 0
      let duration = 0
      const myroute = result.routes[0]

      if (!myroute) {
        return
      }

      for (let i = 0; i < myroute.legs.length; i++) {
        total += myroute.legs[i].distance.value
        duration += myroute.legs[i].duration.value
      }

      this.distance = total
      this.DeafultTime = duration
      total = total / 1000
      this.distance = total + this.$t('KM')
    },
    render (destination) {
      const self = this
      this.loading = true
      var req = {
        origin: self.from,
        destination: destination,
        waypoints: self.waypoints,
        departureTime: null,
      }
      axios.post('https://map-api-ubzex7qxpa-ew.a.run.app/routes/computeRouteWithTrafficLength', req).then(res => {
        self.timeNow = res.data[0].statistics.duration.text
        self.expextedTime = res.data[0].statistics.staticDuration.text
        self.rdistance = res.data[0].statistics.distance.text
        GoogleMapsLoader.KEY = 'AIzaSyC6Wt_40qWZ7up5hvZi9GwzxYWmwjnXggs'
        GoogleMapsLoader.VERSION = '3.35'
        GoogleMapsLoader.load((google) => {
          var colors = { TRAFFIC_JAM: '#d74342', SLOW: '#e69e01', NORMAL: '#4078c9' }
          const decodedPath = res.data[0].polyline.geoJsonLinestring.coordinates
          const decodedPathLatLng = decodedPath.map(elt => { return { lat: elt[1], lng: elt[0] } })
          self.directionsRenderer.setMap(null)
          res.data[0].travelAdvisory.speedReadingIntervals.forEach((step) => {
            const currentPath = decodedPathLatLng.slice(step.startPolylinePointIndex === 0 ? 0 : (step.startPolylinePointIndex - 1), step.endPolylinePointIndex)
            var polylineOptionsActual = new google.maps.Polyline({
              path: currentPath,
              strokeColor: colors[step.speed],
              geodesic: true,
              strokeOpacity: 1,
              strokeWeight: 6,
            })
            polylineOptionsActual.setMap(self.smap)
            self.routes.push(polylineOptionsActual)
          })
        })
      })
    },
  },

}
</script>

<style lang="scss" >
  .overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
  }

  i.fa.fa-exclamation {
    color: red !important;
  }

  .va-modal__inner {
    max-width: 100%;
  }

  .unitsNames-width {
    max-width: 177px;
  }

  #sidebar {
    flex-basis: 15rem;
    flex-grow: 1;
    padding: 1rem;
    max-width: 30rem;
    height: 70vh;
    box-sizing: border-box;
    overflow: auto;
  }

  #map {
    flex-basis: 0;
    flex-grow: 4;
    height: 70vh;
  }

  .va-modal__close {
    right: auto;
    left: 1rem;
  }

  .calcRoute {
    bottom: 92px !important;
    right: 11px !important;
  }
</style>
